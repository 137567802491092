.root {
  @apply grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
}

.item {
  @apply h-auto rounded shadow hover:shadow-md;

  & a {
    @apply flex h-full w-full flex-col;
  }
}

.itemImage {
  @apply aspect-[4/3] w-full last:rounded-b;

  & img {
    @apply h-full w-full rounded-t;
    &[data-imagefit='cover'] {
      @apply object-cover;
    }
    &[data-imagefit='contain'] {
      @apply object-contain;
    }
  }
}

.itemHeading {
  @apply text-content-focus text-base font-semibold;
  /* @apply my-4 px-6 text-xl font-bold last:pb-6; */
  @apply my-4 px-6 last:pb-6;
}

.itemText {
  /* @apply mt-2 px-6 text-base last:pb-6; */
  @apply mt-2 px-6 text-base last:mb-6;
}

.itemActions {
  @apply mt-4 flex flex-1 items-end px-6 last:pb-6;
}
